<div class="section movie-detail" *ngIf="movie">
  <div class="hero has-radius bg-black-bis m-b-xxl">
    <div class="backdrop">
      <figure class="has-radius">
        <img
          [src]="movie.thumbnail"
          alt="{{ movie.name }} cover"
          width="100%"
        />
      </figure>
    </div>
    <div class="container">
      <div class="columns p-y-xxl">
        <div class="column is-6-mobile is-2-tablet">
          <figure class="has-radius">
            <img
              [src]="movie.thumbnail"
              alt="{{ movie.name }} cover"
              width="100%"
            />
          </figure>
        </div>
        <div class="column is-12-mobile is-10-tablet has-text-grey">
          <h1 class="m-t-0 has-text-white m-b-sm m-l-xl-desktop">
            <span class="is-regular p-r-xs">
              {{ movie.spoilers.length }}
              <ng-container [ngPlural]="movie.spoilers.length">
                <ng-template ngPluralCase="=0">spoiler</ng-template>
                <ng-template ngPluralCase="=1">spoiler</ng-template>
                <ng-template ngPluralCase="other">spoilers</ng-template>
              </ng-container>
            </span>
            <ng-container i18n="@@forMovie"> for the movie </ng-container>
            <span>{{ movie.name }}</span>
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <!-- Spoiler list -->
    <app-spoiler-card
      *ngFor="let spoiler of movie.spoilers"
      [spoiler]="spoiler"
    ></app-spoiler-card>
  </div>

  <div class="container">
    <div class="columns">
      <div
        class="
          column
          is-10-tablet
          is-offset-1-tablet
          is-8-desktop
          is-offset-2-desktop
          is-6-widescreen
          is-offset-3-widescreen
          has-text-centered
        "
      >
        <div
          class="
            card
            p-x-xl
            m-t-xl m-b-xl
            bg-danger
            shadow
            p-y-xl
            has-text-light
          "
        >
          <h3 i18n="@@workDetailSpoilersAreAddedByYou">
            Spoilers are added by you
          </h3>
          <p i18n="@@workDetailBecomeAContributor">
            Become a contributor adding a spoiler to this movie :
          </p>
          <a
            routerLink="spoilers/create"
            class="button is-light"
            rel="nofollow"
          >
            <span class="has-text-danger" i18n="@@workDetailAddASpoiler"
              >Add a spoiler</span
            ></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-json-ld [json]="jsonLdMovie"></ngx-json-ld>
