import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { WorkType } from '../../../common/enums/work-type.enum'
import { Work } from '../../../common/interfaces/work.interface'
import { MetaService } from '../../../common/services/meta/meta.service'
import { QuickLinkService } from '../../../common/services/quick-link/quick-link.service'

@Component({
  selector: 'app-home-movie',
  templateUrl: './home-movie.component.html',
  styleUrls: ['./home-movie.component.scss']
})
export class HomeMovieComponent implements OnInit {
  WorkType = WorkType

  constructor(
    private quickLinkService: QuickLinkService,
    private router: Router,

    metaService: MetaService
  ) {
    metaService.setTags('homeMovies')
  }

  ngOnInit() {
    this.quickLinkService.headerLink.next({
      label: `${i18nDynamicStrings.header.addSpoiler}`,
      path: `/movies/spoilers/create`,
      className: 'is-danger'
    })
  }

  onMovieSelected(movie: Work) {
    if (movie.spoilerCount) {
      this.router.navigate([movie.path])
    } else {
      // Go to spoilerCreate.
      if (movie.id) {
        this.router.navigate([movie.path, 'spoilers', 'create'])
      } else {
        this.router.navigate(['/movies', 'spoilers', 'create'], {
          queryParams: { movieSourceId: movie.sourceId }
        })
      }
    }
  }
}
