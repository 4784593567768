import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { SpoilerCreationMode } from './common/enums/spoiler-creation-mode.enum'
import { WorkType } from './common/enums/work-type.enum'
import { Error404Component } from './pages/error404/error404.component'
import { PrivacyLegacyComponent } from './pages/privacy-legacy/privacy-legacy.component'
import { EpisodeDetailComponent } from './resources/episode/episode-detail/episode-detail.component'
import { HomeMovieComponent } from './resources/movie/home-movie/home-movie.component'
import { MovieDetailComponent } from './resources/movie/movie-detail/movie-detail.component'
import { SeasonDetailComponent } from './resources/season/season-detail/season-detail.component'
import { SpoilerCreateComponent } from './resources/spoiler/spoiler-create/spoiler-create.component'
import { HomeTvShowComponent } from './resources/tv-show/home-tv-show/home-tv-show.component'
import { TvShowDetailComponent } from './resources/tv-show/tv-show-detail/tv-show-detail.component'
import { WorkIndexComponent } from './resources/work/work-index/work-index.component'

const routes: Routes = [
  // Movies.
  {
    path: '',
    component: HomeMovieComponent
  },
  {
    path: 'movies/spoilers/create',
    component: SpoilerCreateComponent,
    data: {
      mode: SpoilerCreationMode.Movie
    }
  },
  {
    path: 'movies/:idAndSlug',
    component: MovieDetailComponent
  },
  {
    path: 'movies/:idAndSlug/spoilers/create',
    component: SpoilerCreateComponent,
    data: {
      mode: SpoilerCreationMode.Movie
    }
  },
  {
    path: 'movies/index/:firstLetter',
    component: WorkIndexComponent,
    data: {
      workType: WorkType.Movie
    }
  },
  // Tv Shows.
  {
    path: 'tv-shows',
    component: HomeTvShowComponent
  },
  {
    path: 'tv-shows/spoilers/create',
    component: SpoilerCreateComponent,
    data: {
      mode: SpoilerCreationMode.Episode
    }
  },
  {
    path: 'tv-shows/:idAndSlug',
    component: TvShowDetailComponent
  },
  {
    path: 'tv-shows/:tvShowIdAndSlug/spoilers/create',
    component: SpoilerCreateComponent,
    data: {
      mode: SpoilerCreationMode.Episode
    }
  },
  {
    path: 'tv-shows/:tvShowIdAndSlug/seasons/:seasonNumber',
    component: SeasonDetailComponent
  },
  {
    path: 'tv-shows/:tvShowIdAndSlug/seasons/:seasonNumber/episodes/:episodeNumberAndSlug',
    component: EpisodeDetailComponent
  },
  {
    path: 'tv-shows/:tvShowIdAndSlug/seasons/:seasonNumber/episodes/:episodeNumberAndSlug/spoilers/create',
    component: SpoilerCreateComponent,
    data: {
      mode: SpoilerCreationMode.Episode
    }
  },
  {
    path: 'tv-shows/index/:firstLetter',
    component: WorkIndexComponent,
    data: {
      workType: WorkType.TvShow
    }
  },
  // Common.
  {
    path: 'privacy-legacy',
    component: PrivacyLegacyComponent
  },
  {
    path: '404',
    component: Error404Component
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
