<footer class="footer bg-black-bis has-text-white">
  <div class="container">
    <div class="columns">
      <div class="column is-6">
        <p class="h2 m-b-0" i18n="@@footerBiggestCollection">
          Spoilme is the world’s biggest collection of Movie and TV Show
          spoilers
        </p>
      </div>
      <div class="column is-offset-2 is-4 has-text-right">
        <div class="footer__community">
          <p i18n="@@moreThanSpoilers"><b>More than 13 000 spoilers !</b></p>

          <ul>
            <li>
              <a class="ghost" routerLink="/" i18n="@@movies">Movies</a>
            </li>
            <li>
              <a class="ghost" routerLink="/tv-shows" i18n="@@tvShows"
                >Tv Shows</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <hr class="bg-black" />
      </div>
    </div>

    <div class="columns" id="movie-index">
      <div class="column is-6 is-offset-3" *ngIf="workType === WorkType.Movie">
        <span i18n="@@allMovies">All movies:</span>
        <a
          routerLink="/movies/index/{{ letter }}"
          class="has-text-danger"
          *ngFor="let letter of alphabet"
          >{{ letter }}</a
        >
      </div>
      <div class="column is-6 is-offset-3" *ngIf="workType === WorkType.TvShow">
        <span i18n="@@allTvShows">All TV shows:</span>
        <a
          routerLink="/tv-shows/index/{{ letter }}"
          *ngFor="let letter of alphabet"
          >{{ letter }}</a
        >
      </div>
    </div>

    <div class="columns has-text-grey-light">
      <div class="column is-6">
        <p class="content is-small" i18n="@@footerPoweredBy">
          Spoilme ® 2019 Powered by
          <a
            class="has-text-grey-light"
            href="https://www.buddyweb.fr"
            target="_blank"
            >Buddyweb</a
          >
          with fun, laughs, some 🍿and all of you.
        </p>
      </div>
      <div class="column is-6 has-text-right">
        <p class="content is-small">
          <span>Languages:</span>
          <a
            class="m-l-sm has-text-white"
            [ngClass]="{ 'has-text-danger': lang === 'en' }"
            [href]="translationsUrls.en"
            >EN</a
          >
          <a
            class="m-l-sm has-text-white"
            [ngClass]="{ 'has-text-danger': lang === 'fr' }"
            [href]="translationsUrls.fr"
            >FR</a
          >
          <a
            class="m-l-sm has-text-white"
            [ngClass]="{ 'has-text-danger': lang === 'es' }"
            [href]="translationsUrls.es"
            >ES</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>
