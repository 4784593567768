import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { BreadcrumbLink } from '../../interfaces/breadcrumb-link.interface'

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  public breadcrumbLinks: Subject<BreadcrumbLink[]> = new Subject()
}
