import { Component, OnInit } from '@angular/core'

import { environment } from '../../../environments/environment'
import { BreadcrumbLink } from '../../common/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../common/services/breadcrumb/breadcrumb.service'
import { JsonLdService } from '../../common/services/json-ld/json-ld.service'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[] = []
  jsonLDBreadcrumbs: object

  baseUrl: string = environment.baseUrl

  constructor(
    private breadcrumbService: BreadcrumbService,
    private jsonLdService: JsonLdService
  ) {}

  ngOnInit() {
    this.breadcrumbService.breadcrumbLinks.subscribe(
      (res: BreadcrumbLink[]) => {
        setTimeout(() => {
          this.breadcrumbLinks = res
          this.jsonLDBreadcrumbs = this.jsonLdService.getJsonLdBreadcrumbs(
            this.breadcrumbLinks
          )
        }, 0)
      }
    )
  }
}
