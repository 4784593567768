import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Episode } from '../../../common/interfaces/episode.interface'
import { TvShow } from '../../../common/interfaces/tv-show.interface'
import { MetaService } from '../../../common/services/meta/meta.service'
import { QuickLinkService } from '../../../common/services/quick-link/quick-link.service'
import { ResourceService } from '../../../common/services/resource/resource.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb/breadcrumb.service'
import { WorkCardLayout } from '../../../common/enums/work-card-layout'
import { JsonLdService } from '../../../common/services/json-ld/json-ld.service'
import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { i18nMeta } from '../../../../locale/i18n-meta'

@Component({
  selector: 'app-tv-show-detail',
  templateUrl: './tv-show-detail.component.html',
  styleUrls: ['./tv-show-detail.component.scss']
})
export class TvShowDetailComponent implements OnInit {
  tvShow: TvShow
  jsonLdTvShow: object

  WorkCardLayout = WorkCardLayout

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private metaService: MetaService,
    private breadcrumbService: BreadcrumbService,
    private quickLinkService: QuickLinkService,
    private jsonLdService: JsonLdService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: { idAndSlug: string }) => {
      const tvShowId: string = params.idAndSlug.substring(
        0,
        params.idAndSlug.indexOf('-')
      )
      this.resourceService
        .show('tv-shows', tvShowId)
        .subscribe((tvShowRes: TvShow) => {
          this.tvShow = tvShowRes
          this.jsonLdTvShow = this.jsonLdService.getJsonLdTvShow(this.tvShow)

          this.breadcrumbService.breadcrumbLinks.next([
            {
              label: i18nDynamicStrings.misc.tvShows,
              path: '/tv-shows'
            },
            {
              label: `${this.tvShow.name}`,
              path: `/tv-shows/${params.idAndSlug}`
            }
          ])

          this.metaService.setDynamicTags(
            i18nMeta.getTvShowDetailMeta(this.tvShow, params.idAndSlug)
          )

          this.quickLinkService.headerLink.next({
            label: `${i18nDynamicStrings.header.addSpoilerFor} ${this.tvShow.name}`,
            path: `/tv-shows/${params.idAndSlug}/spoilers/create`,
            className: 'is-info'
          })
        })
    })
  }

  getEpisodeDetailOrSpoilerCreateRouterLink(episode: Episode): string[] {
    return episode.spoilerCount
      ? [episode.path]
      : [episode.path].concat(['spoilers', 'create'])
  }
}
