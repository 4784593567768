import { Injectable } from '@angular/core'
import { Title, Meta } from '@angular/platform-browser'
import { MetaObject } from '../../interfaces/meta-object.interface'
import { appConstants } from '../../../app.constants'
import { environment } from '../../../../environments/environment'
import { i18nMeta } from '../../../../locale/i18n-meta'

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  metaTitleSuffix = ' | Spoil Me'

  constructor(private title: Title, private meta: Meta) {}

  defaultMeta: MetaObject = {
    title: 'Spoil Me',
    description: `Ruin any movie ever with The 1st Open Movie Spoiler Database In The World. More than 10 000 Movies to Spoil !`,
    path: '',
    ogImage: '/assets/images/logo-spoilme.svg'
  }

  setTags(pageName: string): void {
    const meta: MetaObject = i18nMeta.static[pageName] || this.defaultMeta

    this.title.setTitle(
      meta.title + this.metaTitleSuffix || this.defaultMeta.title
    )
    this.meta.addTags([
      {
        name: 'description',
        content: meta.description || this.defaultMeta.description
      },
      {
        name: 'og:description',
        content: meta.description || this.defaultMeta.description
      },
      {
        name: 'og:title',
        content: meta.title || this.defaultMeta.title
      },
      {
        name: 'og:url',
        content: environment.baseUrl + meta.path
      },
      {
        name: 'og:image',
        content:
          environment.baseUrl + (meta.ogImage || this.defaultMeta.ogImage)
      }
    ])

    if (meta.noIndex) {
      this.meta.addTag({
        name: 'robots',
        content: 'noindex'
      })
    } else {
      this.meta.removeTag("name='robots'")
    }
  }

  setDynamicTags(metaObject: MetaObject): void {
    this.title.setTitle(
      `${metaObject.title || this.defaultMeta.title} | ${
        appConstants.META_TITLE_SUFFIX
      }`
    )
    this.meta.addTags([
      {
        name: 'description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:title',
        content: metaObject.title || this.defaultMeta.title
      },
      {
        name: 'og:url',
        content: environment.baseUrl + metaObject.path
      },
      {
        name: 'og:image',
        content:
          environment.baseUrl + (metaObject.ogImage || this.defaultMeta.ogImage)
      }
    ])
  }
}
