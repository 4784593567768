import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { combineLatest } from 'rxjs'

import { WorkType } from '../../../common/enums/work-type.enum'
import { Movie } from '../../../common/interfaces/movie.interface'
import { MetaService } from '../../../common/services/meta/meta.service'
import { ResourceService } from '../../../common/services/resource/resource.service'
import { i18nMeta } from '../../../../locale/i18n-meta'

@Component({
  selector: 'app-work-index',
  templateUrl: './work-index.component.html',
  styleUrls: ['./work-index.component.scss']
})
export class WorkIndexComponent implements OnInit {
  works: Movie[]
  firstLetter: string

  workType: WorkType
  WorkType = WorkType

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data
    ]).subscribe(async ([routeParams, routeData]: any[]) => {
      this.workType = routeData.workType
      this.firstLetter = routeParams.firstLetter

      if (this.workType === WorkType.Movie) {
        this.resourceService
          .list('movies', { firstLetter: this.firstLetter })
          .subscribe((movies: Movie[]) => {
            this.works = movies
          })
      }

      if (this.workType === WorkType.TvShow) {
        this.resourceService
          .list('tv-shows', { firstLetter: this.firstLetter })
          .subscribe((movies: Movie[]) => {
            this.works = movies
          })
      }

      this.metaService.setDynamicTags(
        i18nMeta.getWorkIndexMeta(this.workType, this.firstLetter)
      )
    })
  }
}
