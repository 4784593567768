import { Component, Input } from '@angular/core'
import { WorkCardLayout } from 'src/app/common/enums/work-card-layout'

import { Episode } from '../../../common/interfaces/episode.interface'

@Component({
  selector: 'app-episode-card',
  templateUrl: './episode-card.component.html',
  styleUrls: ['./episode-card.component.scss']
})
export class EpisodeCardComponent {
  @Input() episode: Episode
  @Input() layout: WorkCardLayout

  WorkCardLayout = WorkCardLayout
}
