import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Movie } from '../../../common/interfaces/movie.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb/breadcrumb.service'
import { JsonLdService } from '../../../common/services/json-ld/json-ld.service'
import { MetaService } from '../../../common/services/meta/meta.service'
import { QuickLinkService } from '../../../common/services/quick-link/quick-link.service'
import { ResourceService } from '../../../common/services/resource/resource.service'
import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { i18nMeta } from '../../../../locale/i18n-meta'

@Component({
  selector: 'app-movie-detail',
  templateUrl: './movie-detail.component.html',
  styleUrls: ['./movie-detail.component.scss']
})
export class MovieDetailComponent implements OnInit {
  movie: Movie
  jsonLdMovie: object

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private quickLinkService: QuickLinkService,
    private metaService: MetaService,
    private breadcrumbService: BreadcrumbService,
    private jsonLdService: JsonLdService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: { idAndSlug: string }) => {
      const movieId: string = params.idAndSlug.substring(
        0,
        params.idAndSlug.indexOf('-')
      )

      // TODO: Scroll to spoiler if in fragment (from SpoilerCreate)

      this.resourceService
        .show('movies', movieId)
        .subscribe((movieRes: Movie) => {
          this.movie = movieRes
          this.jsonLdMovie = this.jsonLdService.getJsonLdMovie(this.movie)

          this.metaService.setDynamicTags(
            i18nMeta.getMovieDetailMeta(this.movie, params.idAndSlug)
          )

          this.breadcrumbService.breadcrumbLinks.next([
            {
              label: i18nDynamicStrings.misc.movies,
              path: '/'
            },
            {
              label: `${this.movie.name}`,
              path: `/movies/${params.idAndSlug}/`
            }
          ])

          this.quickLinkService.headerLink.next({
            label: `${i18nDynamicStrings.header.addSpoilerFor} ${this.movie.name}`,
            path: `/movies/${params.idAndSlug}/spoilers/create`,
            className: 'is-danger'
          })
        })
    })
  }
}
