import { Component, OnInit } from '@angular/core'
import { MetaService } from '../../common/services/meta/meta.service'
import { BreadcrumbService } from '../../common/services/breadcrumb/breadcrumb.service'

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  constructor(metaService: MetaService) {
    metaService.setTags('error404')
  }

  ngOnInit() {}
}
