import { Component, Input } from '@angular/core'

import { environment } from '../../../environments/environment'
import { WorkType } from '../../common/enums/work-type.enum'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() workType: WorkType

  lang: string = environment.language
  translationsUrls: { [key: string]: string } = environment.translationUrls
  alphabet: string[] = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
  WorkType = WorkType
}
