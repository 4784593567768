import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class QuickLinkService {
  // TODO: No follow property for spoilerCreate links
  public headerLink: Subject<{
    label: string
    path: string
    className?: string
  }> = new Subject()
}
