import { WorkType } from '../app/common/enums/work-type.enum'
import { Episode } from '../app/common/interfaces/episode.interface'
import { MetaObject } from '../app/common/interfaces/meta-object.interface'
import { Movie } from '../app/common/interfaces/movie.interface'
import { Season } from '../app/common/interfaces/season.interface'
import { TvShow } from '../app/common/interfaces/tv-show.interface'

export const i18nMeta = {
  // Dynamic.
  getMovieDetailMeta: (m: Movie, idAndSlug: string): MetaObject => ({
    path: `/movies/${idAndSlug}`,
    title: `${m.name} Movie Spoilers`,
    description: `Movie Spoiler for "${m.name}". Plot, Ending or Twist Explained in 100 Characters.`
  }),
  getTvShowDetailMeta: (t: TvShow, idAndSlug: string): MetaObject => ({
    path: `/tv-shows/${idAndSlug}`,
    title: `${t.name} Tv Show Spoilers`,
    description: `All the spoilers for the TV Show "${t.name}" (${t.seasons.length} seasons) on the biggest spoiler database : Spoil Me.`
  }),
  getSeasonDetailMeta: (s: Season, tvShowIdAndSlug: string): MetaObject => ({
    path: `/tv-shows/${tvShowIdAndSlug}/seasons/${s.number}`,
    title: `${s.tvShow.name} Season ${s.number} Tv Show Spoilers`,
    description: `All the spoilers for the Season ${s.number} of the TV Show "${s.tvShow.name}" on the biggest spoiler database : Spoil Me.`
  }),
  getEpisodeDetailMeta: (
    e: Episode,
    tvShowIdAndSlug: string,
    episodeNumberAndSlug: string
  ): MetaObject => ({
    path: `/tv-shows/${tvShowIdAndSlug}/seasons/${e.season.number}/episodes/${episodeNumberAndSlug}`,
    title: `${e.season.tvShow.name} ${e.name} Spoilers`,
    description: `Spoilers for the episode ${e.name} from season ${e.season.number} of the TV Show "${e.season.tvShow.name}" on Spoil Me.`
  }),
  getWorkIndexMeta: (workType: WorkType, firstLetter: string): MetaObject => {
    if (workType === WorkType.Movie) {
      return {
        path: `/movies/index/${firstLetter}`,
        title: `${firstLetter} movies spoiled on Spoilme`,
        description: `${firstLetter} movies spoiled on Spoilme`
      }
    }
    if (workType === WorkType.TvShow) {
      return {
        path: `/tv-shows/index/${firstLetter}`,
        title: `${firstLetter} tv shows spoiled on Spoilme`,
        description: `${firstLetter} tv shows spoiled on Spoilme`
      }
    }
  },
  static: {
    homeMovies: {
      title: `World's 1st Open Movie Spoiler Database`,
      description:
        'Ruin any movie ever with The 1st Open Movie Spoiler Database In The World. More than 10 000 Movies to Spoil !',
      path: ''
    },
    homeTvShows: {
      title: `TV Shows Spoilers Database`,
      description: `Ruin any Tv Show ever with The 1st Open Tv Show Spoiler Database In The World.`,
      path: 'tv-shows'
    },
    spoilerCreate: {
      title: 'Add your spoiler',
      path: '/movies/spoilers/create',
      noIndex: true
    },
    privacyLegacy: {
      title: `Privacy Legacy`,
      path: 'privacy-legacy'
    },
    error404: {
      title: `Error 404 : Page not found`,
      path: '404'
    }
  }
}
