<div class="section section--home section--home--tvshow">
  <div class="container">
    <div class="columns">
      <div class="column is-12-mobile is-7-tablet is-6-fullhd">
        <div class="full-screen">
          <h1 i18n="@@homeTvShowTitle">
            <span class="has-text-link">Welcome</span> to the world's 1st Open
            TV Show Spoiler Database
          </h1>
          <h2 class="is-2" i18n="@@homeTvShowSubtitle">
            Find a TV Show to spoil
          </h2>
          <div class="searchbar-wrapper relative">
            <app-search-bar
              placeholder="Game of Thrones, Stranger Things, The walking dead..."
              [workTypes]="[WorkType.TvShow]"
              (resultSelected)="onTvShowSelected($event)"
            ></app-search-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h2 class="is-2 m-b-0 m-t-xxl" i18n="@@homeMovieYouHeardAboutUs">
          You heard about us on
        </h2>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="logos-press">
          <figure>
            <img src="/assets/images/logo-kombini.png" alt="logo kombini" />
          </figure>

          <figure>
            <img src="/assets/images/logo-slate.png" alt="logo slate" />
          </figure>

          <figure>
            <img
              src="/assets/images/logo-mashable.svg"
              width="200px"
              alt="logo Mashable"
            />
          </figure>

          <figure>
            <img src="/assets/images/logo-genbeta.png" alt="logo genbeta" />
          </figure>

          <figure>
            <img src="/assets/images/logo-redetv.png" alt="logo redetv" />
          </figure>

          <figure>
            <img src="/assets/images/logo-melty.png" alt="logo melty" />
          </figure>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <hr class="m-t-gutter m-b-xl" />
      </div>
    </div>
  </div>
</div>
