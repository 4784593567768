import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'

import { environment } from '../../../environments/environment'
import { appConstants } from '../../app.constants'
import { SpoilerCreationMode } from '../../common/enums/spoiler-creation-mode.enum'
import { WorkType } from '../../common/enums/work-type.enum'
import { HTMLInputEvent } from '../../common/interfaces/html-input-event.interface'
import { Work } from '../../common/interfaces/work.interface'
import { ResourceService } from '../../common/services/resource/resource.service'

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInputEl: ElementRef

  @Input() workTypes: WorkType[]
  @Input() placeholder = 'Search...'
  @Input() syncResults: Work[]

  @Output() resultSelected: EventEmitter<Work> = new EventEmitter()

  results: Work[] = []
  showList = false
  focusedItemIndex: number
  SpoilerCreationMode = SpoilerCreationMode
  asyncMode = true
  loading = false

  constructor(
    private elementRef: ElementRef,
    private resourceService: ResourceService
  ) {}

  ngOnInit() {
    if (this.syncResults && this.syncResults.length) {
      this.asyncMode = false
    }
  }

  onSearchInputKeyup(event: HTMLInputEvent) {
    this.showList = true

    // Navigate through results
    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      return this.navigateThroughResults(event.key)
    }

    delete this.focusedItemIndex
    const terms: string = event.target.value

    this.results = []

    if (terms) {
      if (this.asyncMode) {
        this.loading = true
        this.workTypes.forEach((workType: WorkType) => {
          if (workType === WorkType.Movie) {
            this.resourceService
              .list('movies/search', {
                terms,
                lang: environment.language
              })
              .subscribe((searchResultsRes: Work[]) => {
                this.results = searchResultsRes
                this.loading = false
              })
            // TODO: Catch
          } else if (workType === WorkType.TvShow) {
            this.resourceService
              .list('tv-shows/search', { terms, lang: environment.language })
              .subscribe((searchResultsRes: Work[]) => {
                this.results = searchResultsRes
                this.loading = false
              })
            // TODO: Catch
          }
        })
      } else {
        // If we are using synchronous data, we search through our syncResults array.
        this.results = this.syncResults
          .filter((w) => w.name.toLowerCase().indexOf(terms.toLowerCase()) > -1)
          .slice(0, appConstants.SEARCH_MAX_RESULTS)
      }
    }
  }

  // Use arrowKeys and enter to select and navigate through results
  navigateThroughResults(key: string): void {
    if (key === 'ArrowDown') {
      if (typeof this.focusedItemIndex === 'undefined') {
        this.showList = true
        this.focusedItemIndex = 0
      } else if (this.focusedItemIndex < this.results.length - 1) {
        this.focusedItemIndex++
      }
    } else if (key === 'ArrowUp') {
      if (!this.focusedItemIndex) {
        this.showList = false
        delete this.focusedItemIndex
      } else {
        this.focusedItemIndex--
      }
    } else if (
      key === 'Enter' &&
      typeof this.focusedItemIndex !== 'undefined' &&
      this.results[this.focusedItemIndex]
    ) {
      this.select(this.results[this.focusedItemIndex])
      delete this.focusedItemIndex
    }
  }

  select(work: Work): void {
    this.resultSelected.emit(work)
  }

  // Click outside closes list
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (!this.elementRef.nativeElement.contains(eventTarget)) {
      this.showList = false
    }
  }
}
