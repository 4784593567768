import { Injectable } from '@angular/core'

import { appConstants } from '../../../app.constants'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {
  public flashMessage: Subject<{
    message: string
    className: string
  }> = new Subject()

  success(message: string) {
    this.flashMessage.next({ message, className: 'notification is-success' })
    setTimeout(() => {
      this.flashMessage.next(null)
    }, appConstants.FLASH_MESSAGE_TIMEOUT)
  }
  error(message: string) {
    this.flashMessage.next({ message, className: 'notification is-danger' })
    setTimeout(() => {
      this.flashMessage.next(null)
    }, appConstants.FLASH_MESSAGE_TIMEOUT)
  }
}
