import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Power2, TweenLite } from 'gsap'

import { WorkType } from '../../common/enums/work-type.enum'
import { QuickLinkService } from '../../common/services/quick-link/quick-link.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() workType: WorkType

  @ViewChild('menu') menuEl: ElementRef
  @ViewChild('menuItems') menuItemsEl: ElementRef

  showMenu: boolean
  headerLink: { label: string; path: string; className: string }

  WorkType = WorkType

  constructor(private quickLinkService: QuickLinkService) {}

  ngOnInit() {
    this.quickLinkService.headerLink.subscribe(
      (newValue: { label: string; path: string; className: string }) => {
        this.headerLink = newValue
      }
    )
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu
    if (this.showMenu) {
      TweenLite.to(this.menuEl.nativeElement, {
        className: '+=is-open'
      })
      TweenLite.to(this.menuItemsEl.nativeElement, 0.5, {
        opacity: 1,
        left: '0',
        ease: Power2.easeInOut
      })
    } else {
      TweenLite.to(this.menuEl.nativeElement, {
        className: '-=is-open'
      })
      TweenLite.to(this.menuItemsEl.nativeElement, 0.5, {
        opacity: 0.8,
        left: '100%',
        ease: Power2.easeInOut,
        delay: 0.1
      })
    }
  }
}
