import { Component, Input } from '@angular/core'

import { Spoiler } from '../../../common/interfaces/spoiler.interface'

@Component({
  selector: 'app-spoiler-card',
  templateUrl: './spoiler-card.component.html',
  styleUrls: ['./spoiler-card.component.scss']
})
export class SpoilerCardComponent {
  @Input() spoiler: Spoiler
}
