<div
  class="card card--media bg-transparent m-y-gutter"
  [ngClass]="{
    'is-list': layout === WorkCardLayout.Large,
    'is-block': layout === WorkCardLayout.Block,
    'is-landscape': layout === WorkCardLayout.Landscape,
    'is-portrait': layout === WorkCardLayout.Portrait,
    'is-row': layout === WorkCardLayout.Row,
    'is-selected': layout === WorkCardLayout.SelectedWork,
    'is-small': layout === WorkCardLayout.Small,
    'is-minimal': layout === WorkCardLayout.Minimal,
    'is-backdrop': layout === WorkCardLayout.Backdrop,
    'is-landscape-backdrop': layout === WorkCardLayout.LandscapeBackdrop,
    'is-portrait-backdrop': layout === WorkCardLayout.PortraitBackdrop
  }"
>
  <div class="card-poster">
    <figure>
      <img [src]="work.thumbnail" [alt]="work.name" *ngIf="work.thumbnail" />
      <img
        src="/assets/images/logo-spoilme.svg"
        alt="{{ work.name }} cover"
        *ngIf="!work.thumbnail"
      />
    </figure>
  </div>
  <div class="card-content">
    <h4 class="title is-4 is-6-mobile is-regular">
      {{ work.name }}
    </h4>

    <p class="spoiler-count" *ngIf="work.spoilerCount">
      <svg
        *ngIf="layout !== WorkCardLayout.SelectedWork"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g
          id="spoilerIcon"
          data-name="spoiler icon"
          transform="translate(-177 -1266)"
        >
          <circle
            data-name="Ellipse 1"
            cx="18"
            cy="18"
            r="18"
            transform="translate(177 1266)"
            fill="#f44829"
          />
          <g data-name="Groupe 187">
            <path
              data-name="Tracé 3"
              d="M15.267,51.989A1.67,1.67,0,0,0,13.8,50.333V46.057a.571.571,0,0,0-1.065-.287A6.846,6.846,0,0,1,8.5,48.927a8.988,8.988,0,0,1-2.638.349.331.331,0,0,0-.052,0H2.772a2.772,2.772,0,0,0-.263,5.531l.918,3.774a1.108,1.108,0,0,0,1.08.849h.637a1.111,1.111,0,0,0,1.08-1.374l-.787-3.236h.378a.329.329,0,0,0,.052,0,8.989,8.989,0,0,1,2.638.349,6.846,6.846,0,0,1,4.229,3.158.564.564,0,0,0,.49.285.587.587,0,0,0,.153-.02.565.565,0,0,0,.423-.552V53.766a1.67,1.67,0,0,0,1.468-1.655v-.122ZM5.474,57.783l.1.43a.449.449,0,0,1-.436.555H4.506a.448.448,0,0,1-.436-.343l-.877-3.6h1.56l.234.962H4.83a.331.331,0,1,0,0,.662h.319l.164.675h-.1a.331.331,0,0,0,0,.662Zm.042-5.03H3.458a.331.331,0,1,0,0,.662H5.516v.743H2.763a2.109,2.109,0,0,1,.009-4.219H5.516v2.813Zm7.62-2.1v7.067a7.637,7.637,0,0,0-4.473-3.19,9.984,9.984,0,0,0-2.484-.371V49.942a9.984,9.984,0,0,0,2.484-.371,7.638,7.638,0,0,0,4.473-3.19ZM14.6,52.11a1.007,1.007,0,0,1-.806.985V51a1.007,1.007,0,0,1,.806.985Z"
              transform="translate(186.393 1231.516)"
            />
            <path
              data-name="Tracé 4"
              d="M454.218,140.6a.33.33,0,0,0,.223-.086l.955-.87a.331.331,0,0,0-.446-.49l-.955.87a.331.331,0,0,0,.223.576Z"
              transform="translate(-252.458 1141.03)"
            />
            <path
              data-name="Tracé 5"
              d="M454.441,302.11a.331.331,0,0,0-.446.49l.955.87a.331.331,0,0,0,.446-.49Z"
              transform="translate(-252.458 983.474)"
            />
            <path
              data-name="Tracé 6"
              d="M470.918,233.68h-.734a.331.331,0,0,0,0,.663h.734a.331.331,0,1,0,0-.663Z"
              transform="translate(-267.896 1049.554)"
            />
            <path
              data-name="Tracé 7"
              d="M53.817,265.134a.327.327,0,0,0-.03-.057.334.334,0,0,0-.149-.122.349.349,0,0,0-.062-.019.327.327,0,0,0-.13,0,.333.333,0,0,0-.062.019.34.34,0,0,0-.057.03.321.321,0,0,0-.091.091.327.327,0,0,0-.03.057.334.334,0,0,0-.019.062.326.326,0,0,0-.007.065.332.332,0,0,0,.007.065.35.35,0,0,0,.019.062.337.337,0,0,0,.03.057.318.318,0,0,0,.041.05.327.327,0,0,0,.108.072.333.333,0,0,0,.062.019.338.338,0,0,0,.13,0,.34.34,0,0,0,.062-.019.337.337,0,0,0,.057-.03.36.36,0,0,0,.051-.041.337.337,0,0,0,.072-.107.35.35,0,0,0,.019-.062.332.332,0,0,0,.006-.065.326.326,0,0,0-.006-.065A.334.334,0,0,0,53.817,265.134Z"
              transform="translate(134.975 1019.34)"
            />
          </g>
        </g>
      </svg>
      {{ work.spoilerCount }}

      <span i18n="@@workCardSpoilerCount"
        >{work.spoilerCount, plural, =0 {spoiler} =1 {spoiler} other
        {spoilers}}</span
      >

      <br />
    </p>
    <a
      *ngIf="!work.spoilerCount && layout !== WorkCardLayout.SelectedWork"
      class="spoiler-add"
      ><i class="icon icon-plus has-text-white is-hidden-mobile"></i>
      <span i18n="@@workCardAddSpoiler">Add a spoiler</span>
    </a>
  </div>
</div>
