<div class="section" *ngIf="season">
  <div class="hero has-radius bg-black-bis m-b-xxl">
    <div class="backdrop">
      <figure class="has-radius">
        <img
          [src]="season.tvShow.thumbnail"
          alt="{{ season.tvShow.name }} cover"
          width="100%"
        />
      </figure>
    </div>
    <div class="container">
      <div class="columns p-y-xxl">
        <div class="column is-6-mobile is-3-tablet is-2-desktop">
          <figure class="has-radius">
            <img
              [src]="season.tvShow.thumbnail"
              alt="{{ season.tvShow.name }} cover"
              width="100%"
            />
          </figure>
        </div>
        <div
          class="column is-12-mobile is-9-tablet is-10-desktop has-text-grey"
        >
          <h1 class="m-t-0 has-text-white m-b-sm m-l-xl-desktop">
            <h1 class="title is-1 m-b-0-mobile">
              Spoilers for the
              <span>season {{ season.number }}</span> of
              {{ season.tvShow.name }}
            </h1>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="columns">
      <div class="column">
        <h2 class="">
          Episodes
        </h2>
      </div>
    </div>
    <div class="columns flex wrap m-b-xxl">
      <div
        class="column is-6-mobile is-3-tablet is-2-widescreen is-one-seventh-fullhd p-y-0"
        *ngFor="let episode of season.episodes"
      >
        <a
          [routerLink]="getEpisodeDetailOrSpoilerCreateRouterLink(episode)"
          class="ghost"
        >
          <app-episode-card
            [episode]="episode"
            [layout]="WorkCardLayout.PortraitBackdrop"
          ></app-episode-card>
        </a>
      </div>
    </div>
  </div>
</div>

<ngx-json-ld [json]="jsonLdSeason"></ngx-json-ld>
