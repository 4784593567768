<div class="container">
  <div class="columns">
    <div class="column">
      <h1>
        {{ firstLetter }}
        <ng-container
          *ngIf="workType === WorkType.Movie"
          i18n="@@moviesSpoiledOnSpoilme"
          >movies spoiled on Spoilme</ng-container
        >
        <ng-container
          *ngIf="workType === WorkType.TvShow"
          i18n="@@tvShowsSpoiledOnSpoilme"
          >TV shows spoiled on Spoilme</ng-container
        >
      </h1>
    </div>
  </div>

  <div class="columns m-b-xl">
    <div class="column">
      <ul>
        <li *ngFor="let work of works">
          <a
            [routerLink]="work.path"
            [ngClass]="{ 'has-text-danger': workType === WorkType.Movie }"
            >{{ work.name }}</a
          >
        </li>

        <li *ngIf="works && !works.length">
          <ng-container
            *ngIf="workType === WorkType.Movie"
            i18n="@@noMoviesForLetter"
            >No movies for this letter...</ng-container
          >
          <ng-container
            *ngIf="workType === WorkType.TvShow"
            i18n="@@noTvShowForLetter"
            >No TV shows for this letter...</ng-container
          >
        </li>
      </ul>
    </div>
  </div>
</div>
