<div class="section" *ngIf="tvShow">
  <div class="hero has-radius bg-black-bis m-b-xxl">
    <div class="backdrop">
      <figure class="has-radius">
        <img
          [src]="tvShow.thumbnail"
          alt="{{ tvShow.name }} cover"
          width="100%"
        />
      </figure>
    </div>
    <div class="container">
      <div class="columns p-y-xxl">
        <div class="column is-8-mobile is-2-tablet">
          <figure class="has-radius">
            <img
              [src]="tvShow.thumbnail"
              alt="{{ tvShow.name }} cover"
              width="100%"
            />
          </figure>
        </div>
        <div class="column is-12-mobile is-10-tablet has-text-grey">
          <h1 class="m-t-0 has-text-white m-b-sm m-l-xl-desktop">
            <span class="is-regular">
              {{ tvShow.spoilerCount
              }}<ng-container i18n="@@workDetailSpoilers">
                {tvShow.spoilerCount, plural, =0 {spoiler} =1 {spoiler} other
                {spoilers}}</ng-container
              >

              <ng-container i18n="@@workDetailForTheTvShow"
                >&nbsp;for the Tv Show
              </ng-container>
            </span>
            <span>{{ tvShow.name }}</span
            >.
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="columns">
      <div class="column">
        <div class="flex align-center justify-start m-b-md">
          <img src="/assets/icons/magic-square.svg" />
          <h2 class="m-l-gutter m-b-0" i18n="@@workDetailSeasons">Seasons</h2>
        </div>
      </div>
    </div>

    <!-- Season loop -->
    <div *ngFor="let season of tvShow.seasons">
      <div class="columns">
        <div class="column">
          <h3 class="m-b-0">
            <a [routerLink]="['seasons', season.number]">
              <span class="has-text-black"
                ><ng-container i18n="@@workDetailSeason">Season</ng-container>
                {{ season.number }}</span
              ><i class="icon icon-link m-l-md is-size-4"></i>
            </a>
          </h3>
        </div>
      </div>

      <div class="columns flex wrap m-b-xxl">
        <div
          class="column is-12 is-12-mobile is-6-desktop is-4-fullhd p-y-0"
          *ngFor="let episode of season.episodes"
        >
          <a
            class="ghost"
            [routerLink]="getEpisodeDetailOrSpoilerCreateRouterLink(episode)"
          >
            <app-episode-card
              [episode]="episode"
              [layout]="WorkCardLayout.LandscapeBackdrop"
            ></app-episode-card>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-json-ld [json]="jsonLdTvShow"></ngx-json-ld>
