import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { SpoilerCreationMode } from '../../../common/enums/spoiler-creation-mode.enum'
import { WorkCardLayout } from '../../../common/enums/work-card-layout'
import { WorkType } from '../../../common/enums/work-type.enum'
import { Episode } from '../../../common/interfaces/episode.interface'
import { Work } from '../../../common/interfaces/work.interface'
import { FlashMessageService } from '../../../common/services/flash-message/flash-message.service'
import { MetaService } from '../../../common/services/meta/meta.service'
import { ResourceService } from '../../../common/services/resource/resource.service'

// TODO: SEO This page has to be no-index.
@Component({
  selector: 'app-spoiler-create',
  templateUrl: './spoiler-create.component.html',
  styleUrls: ['./spoiler-create.component.scss']
})
export class SpoilerCreateComponent implements OnInit {
  selectedWork: Work
  selectedEpisode: Episode
  mode: SpoilerCreationMode
  form: FormGroup = this.formBuilder.group({
    content: ['', [Validators.required, Validators.maxLength(100)]],
    lang: [environment.language, Validators.required]
  })

  episodes: Episode[]
  placeholder: string
  loadingEpisodes: boolean
  loadingSubmit: boolean

  SpoilerCreationMode = SpoilerCreationMode
  WorkType = WorkType
  WorkCardLayout = WorkCardLayout

  i18nDynamicStrings = i18nDynamicStrings

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private flashMessageService: FlashMessageService,
    private router: Router,
    metaService: MetaService
  ) {
    metaService.setTags('spoilerCreate')
  }

  ngOnInit() {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
      this.activatedRoute.data
    ]).subscribe(async ([params, queryParams, data]: any[]) => {
      this.mode = data.mode
      this.placeholder = this.getPlaceholder(this.mode)

      // Call Work from API.
      let work$: Observable<Work>
      if (this.mode === SpoilerCreationMode.Movie && params.idAndSlug) {
        const movieId: string = params.idAndSlug.substring(
          0,
          params.idAndSlug.indexOf('-')
        )
        work$ = this.resourceService.show('movies', movieId)
      } else if (
        this.mode === SpoilerCreationMode.Movie &&
        queryParams.movieSourceId
      ) {
        work$ = this.resourceService.show(
          'movies/unknown',
          queryParams.movieSourceId
        )
      } else if (
        this.mode === SpoilerCreationMode.Episode &&
        queryParams.tvShowSourceId
      ) {
        work$ = this.resourceService.show(
          'tv-shows/unknown',
          queryParams.tvShowSourceId
        )
      } else if (
        this.mode === SpoilerCreationMode.Episode &&
        params.tvShowIdAndSlug
      ) {
        const tvShowId: string = params.tvShowIdAndSlug.substring(
          0,
          params.tvShowIdAndSlug.indexOf('-')
        )
        work$ = this.resourceService.show('tv-shows', tvShowId)
      }

      if (work$) {
        this.selectedWork = await work$.toPromise().then((res) => res)
      }

      if (this.mode === SpoilerCreationMode.Episode && this.selectedWork) {
        this.episodes = await this.getEpisodes(this.selectedWork)

        if (params.seasonNumber && params.episodeNumberAndSlug) {
          const episodeNumber: string = params.episodeNumberAndSlug.substring(
            0,
            params.episodeNumberAndSlug.indexOf('-')
          )
          this.selectedEpisode = this.episodes.find(
            (e: Episode) =>
              e.seasonNumber.toString() === params.seasonNumber &&
              e.number.toString() === episodeNumber
          )
        }
      }
    })
  }

  submit() {
    const formData = new FormData()
    Object.keys(this.form.value).forEach((property: any) => {
      formData.append(property, this.form.value[property])
    })

    if (!this.selectedWork.id) {
      formData.append('workSourceId', this.selectedWork.sourceId.toString())
    }

    let postUrl: string

    switch (this.mode) {
      case SpoilerCreationMode.Movie:
        postUrl = this.selectedWork.id
          ? `movies/${this.selectedWork.id}/spoilers`
          : 'movies/spoilers'
        break
      case SpoilerCreationMode.Episode:
        postUrl = `episodes/${this.selectedEpisode.id}/spoilers`
        break
    }

    this.resourceService.store(postUrl, formData).subscribe(
      (res: { spoilerId: number; spoilerPath: string }) => {
        this.loadingSubmit = false
        this.flashMessageService.success(
          i18nDynamicStrings.flashMessages.spoilerCreated
        )
        this.router.navigate([res.spoilerPath], {
          fragment: `spoiler-${res.spoilerId}`
        })
      },
      (err) => {
        this.loadingSubmit = false
        this.flashMessageService.error(
          i18nDynamicStrings.flashMessages.errorSpoilerCreation
        )
      }
    )
  }

  getPlaceholder(mode: SpoilerCreationMode): string {
    switch (mode) {
      case SpoilerCreationMode.Movie:
        return i18nDynamicStrings.spoilerCreate.searchMoviePlaceholder
      case SpoilerCreationMode.Episode:
        return i18nDynamicStrings.spoilerCreate.searchTVShowPlaceholder
      default:
        return i18nDynamicStrings.spoilerCreate.searchDefaultPlaceholder
    }
  }

  async selectWork(work: Work): Promise<void> {
    this.selectedWork = work
    if (this.mode === SpoilerCreationMode.Episode) {
      this.episodes = await this.getEpisodes(this.selectedWork)
    }
  }

  async selectEpisode(episode: Episode): Promise<void> {
    this.selectedEpisode = episode
  }

  getEpisodes(tvShow: Work): Promise<Episode[]> {
    this.episodes = []
    this.loadingEpisodes = true

    return this.resourceService
      .list('episodes', {
        tvShowSourceId: tvShow.sourceId,
        lang: environment.language
      })
      .toPromise()
      .then((episodeRes: Episode[]) => {
        this.loadingEpisodes = false
        return episodeRes
      })
  }
}
