import { Component, Input } from '@angular/core'

import { Work } from './../../../common/interfaces/work.interface'
import { WorkCardLayout } from '../../../common/enums/work-card-layout'

@Component({
  selector: 'app-work-card',
  templateUrl: './work-card.component.html',
  styleUrls: ['./work-card.component.scss']
})
export class WorkCardComponent {
  @Input() work: Work
  @Input() layout: WorkCardLayout

  WorkCardLayout = WorkCardLayout
}
