import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { WorkType } from './common/enums/work-type.enum'
import { BreadcrumbService } from './common/services/breadcrumb/breadcrumb.service'
import { EventService } from './common/services/event/event.service'
import { QuickLinkService } from './common/services/quick-link/quick-link.service'
import { WindowRefService } from './common/services/window-ref/window-ref.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  workType: WorkType

  private eventSubscriptions = new Subscription()

  constructor(
    private router: Router,
    private eventService: EventService,
    private quickLinkService: QuickLinkService,
    private breadcrumbService: BreadcrumbService,
    private windowRefService: WindowRefService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.eventSubscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.eventService.routeChanged.next({ url: event.url })
        }
      })
    )
  }

  ngOnInit() {
    this.eventService.routeChanged.subscribe(
      (routeChanged: { url: string }) => {
        // Disable header link and breadcrumb.
        this.quickLinkService.headerLink.next(null)
        this.breadcrumbService.breadcrumbLinks.next([])

        // Scroll to top.
        if (isPlatformBrowser(this.platformId)) {
          this.windowRefService.nativeWindow.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }

        this.workType =
          routeChanged.url.substr(0, 9) === '/tv-shows'
            ? WorkType.TvShow
            : WorkType.Movie
      }
    )
  }
}
