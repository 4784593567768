<div class="container">
  <div class="columns m-x-0 m-b-xl">
    <div class="column is-6 is-offset-3">
      <h1 class="title is-1 m-t-xxl">Are you lost ?</h1>
      <p>It looks like the page you'r looking for could not be found.</p>

      <div style="width: 100%; height: 320; position: relative;">
        <iframe
          src="https://giphy.com/embed/3o7aTskHEUdgCQAXde"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </div>
      <p class="content is-small m-b-gutter has-text-right">
        <a
          href="https://giphy.com/gifs/quentin-tarantino-pulp-fiction-vincent-vega-3o7aTskHEUdgCQAXde"
          >via GIPHY</a
        >
      </p>
      <p>
        Feel free to spoil a <a routerLink="/">movie</a> or a
        <a routerLink="/tv-shows">TV Show</a>.
      </p>
      <hr />
      <p>By the way, we have a game for you. Can you guess those movies ?</p>
      <ol class="m-l-md">
        <li>
          <span>🐔🏃‍♀️</span>
        </li>
        <li>
          <span>🎩🍫</span>
        </li>
        <li>
          <span>👨🏾👩🏿🧒🏿🎡✂️</span>
        </li>
        <li>
          <span>👨🏻‍🦰🎹🚀</span>
        </li>
        <li>
          <span>🐺🌇💸</span>
        </li>
        <li>
          <span>🌪🦈</span>
        </li>
        <li>
          <span>🎅♻️</span>
        </li>
        <li>
          <span>🗣️🐴🐴🐴</span>
        </li>
      </ol>
    </div>
  </div>
</div>
