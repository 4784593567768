export enum WorkCardLayout {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Row = 'ROW',
  SelectedWork = 'SELECTED_WORK',
  Small = 'SMALL',
  Minimal = 'MINIMAL',
  Backdrop = 'BACKDROP',
  List = 'LIST',
  Block = 'BLOCK',
  Large = 'LARGE',
  LandscapeBackdrop = 'LANDSCAPE_BACKDROP',
  PortraitBackdrop = 'PORTRAIT_BACKDROP'
}
