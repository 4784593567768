import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { i18nMeta } from '../../../../locale/i18n-meta'
import { Episode } from '../../../common/interfaces/episode.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb/breadcrumb.service'
import { JsonLdService } from '../../../common/services/json-ld/json-ld.service'
import { MetaService } from '../../../common/services/meta/meta.service'
import { ResourceService } from '../../../common/services/resource/resource.service'
import { QuickLinkService } from '../../../common/services/quick-link/quick-link.service'

@Component({
  selector: 'app-episode-detail',
  templateUrl: './episode-detail.component.html',
  styleUrls: ['./episode-detail.component.scss']
})
export class EpisodeDetailComponent implements OnInit {
  episode: Episode
  jsonLdEpisode: object

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private breadcrumbService: BreadcrumbService,
    private jsonLdService: JsonLdService,
    private quickLinkService: QuickLinkService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: {
        tvShowIdAndSlug: string
        seasonNumber: string
        episodeNumberAndSlug: string
      }) => {
        const tvShowId: string = params.tvShowIdAndSlug.substring(
          0,
          params.tvShowIdAndSlug.indexOf('-')
        )
        const episodeNumber: string = params.episodeNumberAndSlug.substring(
          0,
          params.episodeNumberAndSlug.indexOf('-')
        )

        this.resourceService
          .show(
            `tv-shows/${tvShowId}/seasons/${params.seasonNumber}/episodes`,
            episodeNumber
          )
          .subscribe((episodeRes: Episode) => {
            this.episode = episodeRes
            this.jsonLdEpisode = this.jsonLdService.getJsonLdEpisode(
              this.episode
            )

            this.metaService.setDynamicTags(
              i18nMeta.getEpisodeDetailMeta(
                this.episode,
                params.tvShowIdAndSlug,
                params.episodeNumberAndSlug
              )
            )

            this.breadcrumbService.breadcrumbLinks.next([
              {
                label: i18nDynamicStrings.misc.tvShows,
                path: '/tv-shows'
              },
              {
                label: `${this.episode.season.tvShow.name}`,
                path: `/tv-shows/${params.tvShowIdAndSlug}`
              },
              {
                label: `${i18nDynamicStrings.misc.season} ${this.episode.season.number}`,
                path: `/tv-shows/${params.tvShowIdAndSlug}/seasons/${params.seasonNumber}`
              },
              {
                label: `${this.episode.name}`
              }
            ])

            this.quickLinkService.headerLink.next({
              label: `${i18nDynamicStrings.header.addSpoilerFor} ${this.episode.season.tvShow.name}`,
              path: `/tv-shows/${params.tvShowIdAndSlug}/seasons/${params.seasonNumber}/episodes/${params.episodeNumberAndSlug}/spoilers/create`,
              className: 'is-info'
            })
          })
      }
    )
  }
}
