<nav class="navbar" role="navigation" aria-label="main navigation">
  <div id="navbar-menu" class="navbar-menu" #menu>
    <div class="navbar-brand">
      <a
        class="
          is-hidden-tablet
          flex
          align-center
          jusfify-center
          toggle-button
          m-r-md
        "
        (click)="toggleMenu()"
      >
        <i class="icon icon-menu has-text-white"></i>
      </a>
      <a class="navbar-item" routerLink="/">
        <img
          src="/assets/images/logo-spoilme.svg"
          width="171"
          alt="SpoilMe logo"
        />
      </a>
    </div>
    <div class="navbar-start" #menuItems>
      <a
        class="navbar-item"
        (click)="toggleMenu()"
        routerLink="/"
        [ngClass]="{ 'is-active': workType === WorkType.Movie }"
        i18n="@@movies"
      >
        Movies
      </a>

      <a
        (click)="toggleMenu()"
        class="navbar-item"
        routerLink="/tv-shows"
        [ngClass]="{ 'has-text-link': workType === WorkType.TvShow }"
        i18n="@@tvShows"
      >
        Tv Shows
      </a>
    </div>

    <div class="navbar-end">
      <div class="navbar-item p-a-0">
        <div class="buttons">
          <a
            class="is-hidden-mobile button"
            [ngClass]="headerLink.className"
            routerLink="{{ headerLink.path }}"
            rel="nofollow"
            *ngIf="headerLink"
          >
            <strong>{{ headerLink.label }}</strong>
          </a>
          <a
            class="is-hidden-tablet button is-circle is-danger"
            routerLink="{{ headerLink.path }}"
            rel="nofollow"
            *ngIf="headerLink"
          >
            <i class="icon icon-plus"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
