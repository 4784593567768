<div class="section" *ngIf="episode">
  <div class="hero has-radius bg-black-bis m-b-xxl">
    <div class="backdrop">
      <figure class="has-radius">
        <img
          [src]="episode.thumbnail"
          alt="{{ episode.name }} cover"
          width="100%"
        />
      </figure>
    </div>
    <div class="container">
      <div class="columns p-y-xxl">
        <div class="column is-8-mobile is-2-tablet">
          <figure class="has-radius">
            <img
              [src]="episode.thumbnail"
              alt="{{ episode.name }} cover"
              width="100%"
              *ngIf="episode.thumbnail"
            />
            <img
              src="/assets/images/logo-spoilme.svg"
              alt="{{ episode.name }} cover"
              *ngIf="!episode.thumbnail"
            />
          </figure>
        </div>
        <div class="column is-12-mobile is-10-tablet has-text-grey">
          <h1 class="m-t-0 has-text-white m-b-sm m-l-xl-desktop">
            <span class="is-regular p-r-xs">
              {{ episode.spoilers.length }}
              <ng-container [ngPlural]="episode.spoilers.length">
                <ng-template ngPluralCase="=0">spoiler</ng-template>
                <ng-template ngPluralCase="=1">spoiler</ng-template>
                <ng-template ngPluralCase="other">spoilers</ng-template>
              </ng-container>
              for the episode</span
            >
            {{ episode.name }}.
          </h1>
          <div class="columns">
            <div class="column is-3 has-text-white m-l-xl-desktop">
              <p>
                <span class="is-bold m-b-sm m-r-sm"> Season:</span>
                <span>{{ episode.season.number }}</span>
              </p>
              <p>
                <span class="is-bold m-b-sm m-r-sm"> Tv Show:</span>
                <span>{{ episode.season.tvShow.name }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <!-- Spoiler list -->
    <app-spoiler-card
      *ngFor="let spoiler of episode.spoilers"
      [spoiler]="spoiler"
    ></app-spoiler-card>
  </div>

  <div class="container">
    <div class="columns">
      <div
        class="
          column
          is-10-tablet
          is-offset-1-tablet
          is-8-desktop
          is-offset-2-desktop
          is-6-widescreen
          is-offset-3-widescreen
          has-text-centered
        "
      >
        <div
          class="card p-x-xl m-t-xl m-b-xl bg-link shadow p-y-xl has-text-light"
        >
          <h3>Spoilers are added by you</h3>
          <p>Become a contributor adding a spoiler to this episode :</p>
          <a
            routerLink="spoilers/create"
            class="button is-light"
            rel="nofollow"
          >
            <span class="has-text-link">Add a spoiler</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-json-ld [json]="jsonLdEpisode"></ngx-json-ld>
