<div
  class="section"
  [ngClass]="{
    'movie-mode': mode === SpoilerCreationMode.Movie,
    'episode-mode': mode === SpoilerCreationMode.Episode
  }"
>
  <div class="container">
    <div class="columns">
      <div
        class="column is-12-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-6-fullhd is-offset-3-fullhd"
      >
        <div
          class="card p-a-gutter m-y-gutter"
          [ngClass]="{
            'bg-danger': mode === SpoilerCreationMode.Movie,
            'bg-link': mode === SpoilerCreationMode.Episode
          }"
        >
          <h1
            class="title is-2 m-b-gutter has-text-centered has-text-light"
            i18n="@@spoilerCreateAddYourSpoiler"
          >
            Add your spoiler
          </h1>
          <!-- Work search -->
          <div *ngIf="!selectedWork" class="m-b-lg relative">
            <app-search-bar
              [workTypes]="[
                mode === SpoilerCreationMode.Movie
                  ? WorkType.Movie
                  : WorkType.TvShow
              ]"
              [placeholder]="placeholder"
              (resultSelected)="selectWork($event)"
            ></app-search-bar>
          </div>

          <!-- Selected work -->
          <div *ngIf="selectedWork" class="selected-work has-text-white">
            <app-work-card
              [work]="selectedWork"
              [layout]="WorkCardLayout.SelectedWork"
            ></app-work-card>
            <a (click)="selectedWork = null" class="remove-link"
              ><i class="icon icon-x is-size-3 has-text-white"></i
            ></a>
          </div>

          <!-- Episode search (Episode mode only) -->
          <div
            *ngIf="
              mode === SpoilerCreationMode.Episode &&
              selectedWork &&
              !selectedEpisode
            "
          ></div>

          <div *ngIf="loadingEpisodes" class="spinner--form">
            <div></div>
          </div>

          <div
            *ngIf="
              selectedWork && episodes && episodes.length && !selectedEpisode
            "
            class="m-y-lg relative"
          >
            <app-search-bar
              [placeholder]="
                i18nDynamicStrings.spoilerCreate.searchEpisodePlaceholder
              "
              [workTypes]="[WorkType.Episode]"
              [syncResults]="episodes"
              (resultSelected)="selectEpisode($event)"
            ></app-search-bar>
          </div>

          <!-- Selected episode -->
          <div
            *ngIf="selectedEpisode"
            class="selected-work m-b-gutter has-text-white"
          >
            <app-work-card
              [work]="selectedEpisode"
              [layout]="WorkCardLayout.SelectedWork"
            ></app-work-card>
            <a (click)="selectedEpisode = null" class="remove-link"
              ><i class="icon icon-x is-size-3 has-text-white"></i>
            </a>
          </div>

          <!-- Spoiler textarea -->
          <form
            [formGroup]="form"
            novalidate
            (submit)="submit()"
            class="m-t-gutter"
          >
            <label
              class="m-b-sm has-text-light"
              i18n="@@spoilerCreateWriteYourSpoilerHere"
              >Write your spoiler here</label
            >
            <p class="help flex align-center has-text-light">
              <i
                class="icon icon-zap m-r-sm has-text-warning is-size-4"
                style="position: relative; top: 1px;"
              ></i>
              <span i18n="@@spoilerCreateLessThan100Characters">
                Your spoiler has to be less than 100 characters, go straight to
                the point !
              </span>
            </p>
            <textarea
              name="spoiler-content"
              [placeholder]="
                i18nDynamicStrings.spoilerCreate.spoilerTextareaPlaceholder
              "
              class="textarea is-large"
              maxlength="100"
              formControlName="content"
            ></textarea>

            <!-- Send button -->
            <button
              class="button is-white is-large m-t-gutter"
              [disabled]="form.invalid"
              [ngClass]="{ 'is-loading': loadingSubmit }"
              i18n="@@spoilerCreateSend"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
