import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { NgxJsonLdModule } from '@ngx-lite/json-ld'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { TruncatePipe } from './common/pipes/truncate.pipe'
import { BreadcrumbService } from './common/services/breadcrumb/breadcrumb.service'
import { FlashMessageService } from './common/services/flash-message/flash-message.service'
import { QuickLinkService } from './common/services/quick-link/quick-link.service'
import { SearchBarComponent } from './elements/search-bar/search-bar.component'
import { Error404Component } from './pages/error404/error404.component'
import { PrivacyLegacyComponent } from './pages/privacy-legacy/privacy-legacy.component'
import { BreadcrumbsComponent } from './partials/breadcrumbs/breadcrumbs.component'
import { FlashMessageComponent } from './partials/flash-message/flash-message.component'
import { FooterComponent } from './partials/footer/footer.component'
import { HeaderComponent } from './partials/header/header.component'
import { EpisodeCardComponent } from './resources/episode/episode-card/episode-card.component'
import { EpisodeDetailComponent } from './resources/episode/episode-detail/episode-detail.component'
import { HomeMovieComponent } from './resources/movie/home-movie/home-movie.component'
import { MovieDetailComponent } from './resources/movie/movie-detail/movie-detail.component'
import { SeasonDetailComponent } from './resources/season/season-detail/season-detail.component'
import { SpoilerCardComponent } from './resources/spoiler/spoiler-card/spoiler-card.component'
import { SpoilerCreateComponent } from './resources/spoiler/spoiler-create/spoiler-create.component'
import { HomeTvShowComponent } from './resources/tv-show/home-tv-show/home-tv-show.component'
import { TvShowDetailComponent } from './resources/tv-show/tv-show-detail/tv-show-detail.component'
import { WorkCardComponent } from './resources/work/work-card/work-card.component'
import { MetaService } from './common/services/meta/meta.service';
import { WorkIndexComponent } from './resources/work/work-index/work-index.component'

// configure Bugsnag asap
const bugsnagClient = bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.production ? 'production' : 'development',
  enabledReleaseStages: ['production']
})

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}

@NgModule({
  declarations: [
    AppComponent,
    SearchBarComponent,
    TruncatePipe,
    HomeMovieComponent,
    HomeTvShowComponent,
    HeaderComponent,
    FooterComponent,
    SpoilerCreateComponent,
    MovieDetailComponent,
    TvShowDetailComponent,
    SeasonDetailComponent,
    EpisodeDetailComponent,
    WorkCardComponent,
    Error404Component,
    PrivacyLegacyComponent,
    BreadcrumbsComponent,
    SpoilerCardComponent,
    EpisodeCardComponent,
    FlashMessageComponent,
    WorkIndexComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxJsonLdModule
  ],
  providers: [
    QuickLinkService,
    FlashMessageService,
    BreadcrumbService,
    MetaService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
