import { Inject, Injectable, LOCALE_ID } from '@angular/core'

import { environment } from '../../../../environments/environment'
import { BreadcrumbLink } from '../../interfaces/breadcrumb-link.interface'
import { Episode } from '../../interfaces/episode.interface'
import { Movie } from '../../interfaces/movie.interface'
import { Season } from '../../interfaces/season.interface'
import { Spoiler } from '../../interfaces/spoiler.interface'
import { TvShow } from '../../interfaces/tv-show.interface'

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  getJsonLdBreadcrumbs(breadcrumbLinks: BreadcrumbLink[]): object {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbLinks.map(
        (bL: BreadcrumbLink, index: number) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: bL.label,
          item: bL.path ? environment.baseUrl + bL.path : ''
        })
      )
    }
  }

  getJsonLdMovie(movie: Movie): object {
    return {
      '@context': 'http://schema.org/',
      '@type': 'Movie',
      name: movie.name,
      thumbnailUrl: movie.thumbnail,
      aggregateRating: {
        '@type': 'AggregateRating',
        reviewCount: movie.spoilers.length
      },
      review: movie.spoilers.map((spoiler: Spoiler) => ({
        '@type': 'Review',
        datePublished: spoiler.createdAt,
        description: spoiler.content,
        inLanguage: this.locale,
        author: {
          '@type': 'Person',
          name: 'anonymous'
        }
      }))
    }
  }

  getJsonLdTvShow(tvShow: TvShow): object {
    return {
      '@context': 'http://schema.org/',
      '@type': 'TVSeries',
      name: tvShow.name,
      thumbnailUrl: tvShow.thumbnail,
      containsSeason: tvShow.seasons.map((season: Season) =>
        this.getJsonLdSeason(season)
      )
    }
  }

  getJsonLdSeason(season: Season): object {
    return {
      '@type': 'TVSeason',
      name: `Season ${season.number}`,
      numberOfEpisodes: season.episodes.length,
      episode: season.episodes.map((episode: Episode) =>
        this.getJsonLdEpisode(episode)
      )
    }
  }

  getJsonLdEpisode(episode: Episode): any {
    const jsonLdEpisode: any = {
      '@type': 'TVEpisode',
      episodeNumber: episode.number,
      thumbnailUrl: episode.thumbnail,
      name: episode.name,
      aggregateRating: {
        '@type': 'AggregateRating',
        reviewCount: episode.spoilerCount
      }
    }

    if (episode.spoilers && episode.spoilers.length) {
      jsonLdEpisode.review = episode.spoilers
        ? episode.spoilers.map((spoiler: Spoiler) => ({
            '@type': 'Review',
            datePublished: spoiler.createdAt,
            description: spoiler.content,
            inLanguage: this.locale,
            author: {
              '@type': 'Person',
              name: 'anonymous'
            }
          }))
        : []
    }
    return jsonLdEpisode
  }
}
