// This file contents i18n strings that are not directly in HTML templates.
export const i18nDynamicStrings = {
  spoilerCreate: {
    searchMoviePlaceholder: 'Search for a movie...',
    searchTVShowPlaceholder: 'Search for a tv show...',
    searchEpisodePlaceholder: 'Select an episode...',
    searchDefaultPlaceholder: 'Search...',
    spoilerTextareaPlaceholder: 'Write your spoiler here...'
  },
  flashMessages: {
    spoilerCreated: 'The spoiler has been created !',
    errorSpoilerCreation: 'There has been an error'
  },
  header: {
    addSpoilerFor: 'Add a spoiler for',
    addSpoiler: 'Add a spoiler'
  },
  misc: {
    season: 'Season',
    movies: 'Movies',
    tvShows: 'TV Shows'
  }
}
