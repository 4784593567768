<div class="container">
  <div class="columns m-a-0">
    <div class="column">
      <nav
        class="breadcrumb m-y-md is-size-6"
        aria-label="breadcrumbs"
        *ngIf="breadcrumbLinks && breadcrumbLinks.length"
      >
        <ol class="p-l-0 m-y-colgap">
          <li routerLink="/"><span class="m-r-sm">Home</span></li>
          <li
            *ngFor="
              let breadcrumbLink of breadcrumbLinks;
              let i = index;
              let last = last
            "
          >
            <a [routerLink]="breadcrumbLink.path" *ngIf="!last">
              <span>{{ breadcrumbLink.label }}</span>
            </a>

            <!-- Last item (current) -->
            <a aria-current="page" *ngIf="last" class="is-active">
              <span>{{ breadcrumbLink.label }}</span>
            </a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<ngx-json-ld [json]="jsonLDBreadcrumbs"></ngx-json-ld>
