import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  list(resourceName: string, refineParams?: object): Observable<any> {
    let httpParams = new HttpParams()
    httpParams = httpParams.set('lang', environment.language)

    if (refineParams) {
      Object.keys(refineParams).forEach((key: string) => {
        if (Array.isArray(refineParams[key])) {
          refineParams[key].forEach((value: string) => {
            httpParams = httpParams.append(key, value)
          })
        } else {
          httpParams = httpParams.set(key, refineParams[key])
        }
      })
    }

    return this.http
      .get(`${environment.apiBaseUrl}/${resourceName}`, {
        params: httpParams
      })
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  show(
    resourceName: string,
    id: number | string,
    suffix?: string
  ): Observable<any> {
    let httpParams = new HttpParams()
    httpParams = httpParams.set('lang', environment.language)

    return this.http
      .get(
        `${environment.apiBaseUrl}/${resourceName}/${id}` +
          (suffix ? `/${suffix}` : ''),
        {
          params: httpParams
        }
      )
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  store(resourceName: string, formData: FormData): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/${resourceName}`, formData)
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  update(
    resourceName: string,
    id: number | string,
    formData: FormData
  ): Observable<any> {
    return this.http
      .put(`${environment.apiBaseUrl}/${resourceName}/${id}`, formData)
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  patch(
    resourceName: string,
    id: number | string,
    suffix: string,
    formData: FormData
  ): Observable<any> {
    return this.http
      .patch(
        `${environment.apiBaseUrl}/${resourceName}/${id}/${suffix}`,
        formData
      )
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  delete(resourceName: string, id: number | string): Observable<any> {
    return this.http
      .delete(`${environment.apiBaseUrl}/${resourceName}/${id}`)
      .pipe(
        map((res) => {
          return res
        })
      )
  }
}
