import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { WorkCardLayout } from '../../../common/enums/work-card-layout'
import { Episode } from '../../../common/interfaces/episode.interface'
import { Season } from '../../../common/interfaces/season.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb/breadcrumb.service'
import { JsonLdService } from '../../../common/services/json-ld/json-ld.service'
import { MetaService } from '../../../common/services/meta/meta.service'
import { ResourceService } from '../../../common/services/resource/resource.service'
import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { i18nMeta } from '../../../../locale/i18n-meta'
import { QuickLinkService } from '../../../common/services/quick-link/quick-link.service'

@Component({
  selector: 'app-season-detail',
  templateUrl: './season-detail.component.html',
  styleUrls: ['./season-detail.component.scss']
})
export class SeasonDetailComponent implements OnInit {
  season: Season
  jsonLdSeason: object

  WorkCardLayout = WorkCardLayout

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private breadcrumbService: BreadcrumbService,
    private jsonLdService: JsonLdService,
    private quickLinkService: QuickLinkService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: { tvShowIdAndSlug: string; seasonNumber: string }) => {
        const tvShowId: string = params.tvShowIdAndSlug.substring(
          0,
          params.tvShowIdAndSlug.indexOf('-')
        )

        this.resourceService
          .show(`tv-shows/${tvShowId}/seasons`, params.seasonNumber)
          .subscribe((seasonRes: Season) => {
            this.season = seasonRes
            this.jsonLdSeason = this.jsonLdService.getJsonLdSeason(this.season)

            this.breadcrumbService.breadcrumbLinks.next([
              {
                label: i18nDynamicStrings.misc.tvShows,
                path: '/tv-shows'
              },
              {
                label: `${this.season.tvShow.name}`,
                path: `/tv-shows/${params.tvShowIdAndSlug}`
              },
              {
                label: `${i18nDynamicStrings.misc.season} ${this.season.number}`
              }
            ])

            this.metaService.setDynamicTags(
              i18nMeta.getSeasonDetailMeta(this.season, params.tvShowIdAndSlug)
            )

            this.quickLinkService.headerLink.next({
              label: `${i18nDynamicStrings.header.addSpoilerFor} ${this.season.tvShow.name}`,
              path: `/tv-shows/${params.tvShowIdAndSlug}/spoilers/create`,
              className: 'is-info'
            })
          })
      }
    )
  }

  getEpisodeDetailOrSpoilerCreateRouterLink(episode: Episode): string[] {
    return episode.spoilerCount
      ? [episode.path]
      : [episode.path].concat(['spoilers', 'create'])
  }
}
