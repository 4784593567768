<div class="section section--home">
  <div class="container">
    <div class="columns">
      <div class="column is-12-mobile is-7-tablet is-6-fullhd">
        <div class="full-screen">
          <h1 class="" i18n="@@homeMovieTitle">
            <span class="has-text-danger">Welcome</span> to the world's 1st Open
            Movie Spoiler Database
          </h1>
          <h2 class="is-2" i18n="@@homeMovieSubtitle">Find a movie to spoil</h2>
          <div class="searchbar-wrapper relative">
            <app-search-bar
              placeholder="Avengers: Endgame, Star wars, Gone girl..."
              [workTypes]="[WorkType.Movie]"
              (resultSelected)="onMovieSelected($event)"
            ></app-search-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h2 class="is-2 m-b-0 m-t-xxl" i18n="@@homeMovieYouHeardAboutUs">
          You heard about us on
        </h2>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="logos-press">
          <figure>
            <img src="/assets/images/logo-kombini.png" alt="logo kombini" />
          </figure>

          <figure>
            <img src="/assets/images/logo-slate.png" alt="logo slate" />
          </figure>

          <figure>
            <img
              src="/assets/images/logo-mashable.svg"
              width="200px"
              alt="logo Mashable"
            />
          </figure>

          <figure>
            <img src="/assets/images/logo-genbeta.png" alt="logo genbeta" />
          </figure>

          <figure>
            <img src="/assets/images/logo-redetv.png" alt="logo redetv" />
          </figure>

          <figure>
            <img src="/assets/images/logo-melty.png" alt="logo melty" />
          </figure>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <hr class="m-t-gutter m-b-xl" />
      </div>
    </div>
  </div>
</div>
