import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { i18nDynamicStrings } from '../../../../locale/i18n-dynamic-strings'
import { WorkType } from '../../../common/enums/work-type.enum'
import { Work } from '../../../common/interfaces/work.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb/breadcrumb.service'
import { MetaService } from '../../../common/services/meta/meta.service'
import { QuickLinkService } from '../../../common/services/quick-link/quick-link.service'

@Component({
  selector: 'app-home-tv-show',
  templateUrl: './home-tv-show.component.html',
  styleUrls: ['./home-tv-show.component.scss']
})
export class HomeTvShowComponent implements OnInit {
  WorkType = WorkType
  tvShow: Work

  constructor(
    private quickLinkService: QuickLinkService,
    private router: Router,
    metaService: MetaService,
    breadcrumbService: BreadcrumbService
  ) {
    metaService.setTags('homeTvShows')
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Tv Shows',
        path: '/tv-shows'
      }
    ])
  }

  ngOnInit() {
    this.quickLinkService.headerLink.next({
      label: `${i18nDynamicStrings.header.addSpoiler}`,
      path: `/tv-shows/spoilers/create`,
      className: 'is-info'
    })
  }

  onTvShowSelected(tvShow: Work) {
    // If we have a TvShow detail page, go to it.
    if (tvShow.path) {
      this.router.navigate([tvShow.path])
    } else {
      this.router.navigate(['tv-shows', 'spoilers', 'create'], {
        queryParams: {
          tvShowSourceId: tvShow.sourceId
        }
      })
    }
  }
}
