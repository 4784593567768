import { Component } from '@angular/core'

import { BreadcrumbService } from '../../common/services/breadcrumb/breadcrumb.service'
import { MetaService } from '../../common/services/meta/meta.service'

@Component({
  selector: 'app-privacy-legacy',
  templateUrl: './privacy-legacy.component.html',
  styleUrls: ['./privacy-legacy.component.scss']
})
export class PrivacyLegacyComponent {
  constructor(metaService: MetaService) {
    metaService.setTags('privacyLegacy')
  }
}
